import React from 'react';
import {Copyright} from "lucide-react";

function Footer(props) {
    return <>
        {/* Copyright Footer */}
        <div className="p-6 bg-gray-900 border-t border-gray-700">
            <div className="flex items-center justify-center text-gray-400 text-sm">
                <div className="flex items-center space-x-2">
                    <Copyright className="w-4 h-4"/>
                    <span>{props.currentYear} thgeek.com. All rights reserved.</span>
                </div>
            </div>
        </div>
    </>;
}

export default Footer;