import React from "react";

function ICPCode() {
    return <>
        {/* ICP code */}
        <div className="mt-4 text-center text-gray-400 text-xs">
            <p>渝ICP备20003158号</p>
        </div>
    </>;
}

export default ICPCode;