import React from 'react';
import './App.css';
import Portfolio from "./pages/portfolio/Portfolio";

function App() {
    return (
        <div>
            <Portfolio/>
        </div>
    );
}

export default App;